import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { updateOrderFromTable } from '../../action/orders';
import { fetchCategories } from '../../action/goods';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { isEmpty } from 'lodash';
import Loader from '../utils/Loader';
import { fetchFilials } from '../../action/handbook';
import { fetchSettings } from '../../action/app';
import { roleId } from '../../constants/app';
import { fromStore } from '../../selectors';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const paymentTitle = {
    cash: 'Наличные',
    terminal: 'Терминал',
    online: 'Онлайн-оплата',
    paymented: 'Оплачен'
};

const сashTitle = {
    no: 'под расчет',
    c1000: '1000',
    c1500: '1500',
    c2000: '2000',
    c5000: '5000',
};

class OrdersTable extends Component {      
    constructor(props) {
        super(props);
        this.state = {
          showDetails: Array(this.props.orders.length).fill(false),
          openClient: false
        };
      }
    
    componentDidMount(){
        if(isEmpty(this.props.settings))        
            this.props.fetchSettings();
        
        if(isEmpty(this.props.filials))
            this.props.fetchFilials(null); 
        
        if(isEmpty(this.props.categories))
            this.props.fetchCategories(); 

        if(window.location.pathname.includes('/clients'))
        {
            this.setState({openClient: true})
        } else {
            this.setState({openClient: false})
        }
    }
    
    handleDoubleClick = (item) => () => {
        const url = this.props.isArchive ? '/archive' : '/orders';
        this.props.history.push(`${url}/edit/${+item.id}`)
    }

    handleClickCopy = (text) => () => {
        navigator.clipboard.writeText(text)
    }

    toggleDetails(index) {
        this.setState(prevState => {
          const updatedShowDetails = [...prevState.showDetails]
          updatedShowDetails[index] = !updatedShowDetails[index]
          return { showDetails: updatedShowDetails }
        })
    }

    changePoint = (value, item) => {
        let data = {}

        if(!value)
            data = {
                point: null,
                zone: null
            };

        if(value)
            data = {
                city: this.props.filials.find((el) => el.id === value).city,
                point: this.props.filials.find((el) => el.id === value),
                zone: null
            };

        this.props.updateOrderFromTable(item.id, data, item.isArchive);   
    }

    changeStatus = (value, item) => {
        if(item.status !== value)
        {
            const data = {
                status: value
            };

            if (data.status === 'cancel') {
                return swal({
                    title: "Изменение статуса заказа",
                    text: "Вы действительно хотите отменить заказ?",
                    icon: "warning",
                    dangerMode: true,
                    buttons: ["Отмена", "Отменить заказ"],
                }).then((willDelete) => {
                    if (willDelete) {
                        this.props.updateOrderFromTable(item.id, data, item.isArchive);
                    }
                });
            }

            if (data.status === 'written-off') {
                return swal({
                    title: "Изменение статуса заказа",
                    text: "Вы действительно хотите списать заказ?",
                    icon: "warning",
                    dangerMode: true,
                    buttons: ["Отмена", "Списать заказ"],
                }).then((willDelete) => {
                    if (willDelete) {
                        this.props.updateOrder(item.id, data, item.isArchive);
                    }
                });
            }

            return this.props.updateOrderFromTable(item.id, data, item.isArchive);
            
        }      
    }

    getStatusPay = (order) => {
        if (!order.paymentId) return 'Не оплачено';
        const paymentData = order.paymentData ? JSON.parse(order.paymentData) : null;
        if (!order.paymentData) return 'Не оплачено';

        if (paymentData.status === 'pending') {


    return 'Ожидает оплаты';
          }
      
        if (paymentData.status === 'succeeded') return 'Оплачено';
        if (paymentData.status === 'refundPending') return 'Возврат средств';
        if (paymentData.status === 'refund') return 'Возврат средств выполнен';
        return '';
     }

    renderWok = (good, index, isAggPrice) => {
        return (
            <>
            <tr key={index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                <td>{good.wokData.main.title}</td>
                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{good.count}</td>
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{good.wokData.main.price}</td>            
            </tr>
            {good.wokData.meat && Object.values(good.wokData.meat).map((el) =>
                <tr key={el.id + index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                    <td className="order-table-order-card__name">{'--' + el.data.title}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.price}</td>
                </tr>
            )}
            {good.wokData.topping && Object.values(good.wokData.topping).map((el) =>
                <tr key={el.id + index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                    <td className="order-table-order-card__name">{'--' + el.data.title}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.price}</td>
                </tr>
            )}
            {good.wokData.souce && Object.values(good.wokData.souce).map((el) =>
                <tr key={el.id + index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                    <td className="order-table-order-card__name">{'--' + el.title}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.price}</td>                    
                </tr>
            )}
            </>
        );
    }

    renderGoodTitle = (good) => {
        if (good.categoryName) {
            if (good.categoryName.toLowerCase() === 'пицца') {
                return `${good.data.title} ${good.isMod ? '36 см' : '30 см' }`                
            }
            return good.data.title
        }
        const category = this.props.categories.find(o => o.id === good.category);
        if (!category) return good.data.title;
        if (category.title.toLowerCase() === 'пицца') {
            return( `${good.data.title} ${good.isMod ? '36 см' : '30 см' }`
            );
        }
        return good.data.title
        }

    renderGoods(good, index, isAggPrice){
        if (good.isWok) return this.renderWok(good, index, isAggPrice);

        return (
            <tr key={index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                <td>
                    <div className="order-table-order-card__name">{this.renderGoodTitle(good)}</div>
                </td>
                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{good.count}</td>
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{isAggPrice ? good.oldPrice : good.price}</td>                  
            </tr>
        );  
    }

    renderSelectPoint(item) {
        if (!item.point && !item.zone) return '';
        if(item.zone && !item.zone.point.id && !this.props.filials.find((el) => el.id === item.zone.point)) return '';

        if(item.point) {
            return ({
                value: item.point.id,
                label: `${item.city ? item.city.name : '-'}, ${item.point.street}, ${item.point.house}`
            });
        }
        
        if(item.zone.point.id) {
            return ({
                value: item.zone.point.id,
                label: `${item.city ? item.city.name : '-'}, ${item.zone.point.street}, ${item.zone.point.house}`
            });
        }
        
        return ({
            value: `${this.props.filials.find((el) => el.id === item.zone.point).id}`,
            label: `${item.city ? item.city.name : '-'}, ${this.props.filials.find((el) => el.id === item.zone.point).street}, ${this.props.filials.find((el) => el.id === item.zone.point).house}`
        });             
    }
    
    renderAdress(item) {
        if (!item.street && !item.point) 
        {
            if(!item.city)
                return '-';
            if(item.city)
                return `${item.city.name}`
        }

        if(item.deliveryMethod === "delivery")
            return `${item.city ? item.city.name : ''}${item.area ? ', ' + item.area : ''}${item.street ? ', ' + item.street : ''}${item.house ? ', ' + item.house : ''}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.floor ? `, этаж ${item.floor}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
        
        if(item.deliveryMethod === "own")
            return `${item.city ? item.city.name : ''}${item.point.street ? ', ' + item.point.street: ''}${item.point.house ? ', ' + item.point.house : ''}`;
    }

    renderAdressSelect(item){
        return `${item.city ? item.city.name + ', ' : ''}${item.street}, ${item.house}`;
    }

    renderStatusLabel(status) {
        switch(status) {
            case 'processing': {
                return ({ value: status, label: 'В обработке' });                    
            }
            case 'not-confirmed': {
                return({ value: status, label: 'Не подтвержден' });
            }
            case 'confirmed': {
                return ({ value: status, label: 'Подтвержден' });
            }
            case 'cook': {
                return ({ value: status, label: 'Готовится' });
            }
            case 'cooked': {
                return ({ value: status, label: 'Приготовлен' });
            }
            case 'delivery': {
                return ({ value: status, label: 'В пути' });
            }
            case 'done': {
                return ({ value: status, label: 'Выполнен' });
            }
            case 'written-off': {
                return ({ value: status, label: 'Списан' });
            }
            case 'cancel': {
                return ({ value: status, label: 'Отменен' });
            }
            case 'return': {
                return ({ value: status, label: 'Возврат' });
            }
            case 'deferred': {
                return ({ value: status, label: 'Отложенный' });
            }
            default: {
                return { value: status, label: 'Новый' }
            }
        }
    }

    renderStatus(item) {
        return <div style={{ width: '140px', fontWeight: 300, fontSize: '1rem' }}>
                <Select
                    isLoading={this.props.loadingFilials || this.props.loadingUpdateorder === item.id}
                    isDisabled={(this.props.loadingFilials || this.props.loadingUpdateorder === item.id) || (this.props.role !== roleId.admin && (item.status === 'written-off' || item.status === 'cancel'))}
                    value={this.renderStatusLabel(item.status)}
                    closeMenuOnSelect={true}
                    onChange={(e) => {this.changeStatus(e ? e.value : null, item)}}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                    menuPlacement="auto"
                    options={[
                        { value: 'new', label: 'Новый' },
                        { value: 'processing', label: 'В обработке' },
                        { value: 'not-confirmed', label: 'Не подтвержден' },
                        { value: 'confirmed', label: 'Подтвержден' },
                        { value: 'cook', label: 'Готовится' },
                        { value: 'cooked', label: 'Приготовлен' },
                        { value: 'delivery', label: 'В пути' },
                        { value: 'done', label: 'Выполнен' },
                        { value: 'written-off', label: 'Списан' },
                        { value: 'cancel', label: 'Отменен' },
                        { value: 'return', label: 'Возврат' },
                        { value: 'deferred', label: 'Отложенный' }
                    ]}                                     
                    placeholder="Статус"
                    noOptionsMessage={() => 'Статусы не найдены'}
                />                                    
            </div>
    }

    renderTime(order) {
        if(order.visible_datetime)
            return order.visible_datetime;
        return order.created_at
    }

    renderPointTime(point, zone) {
        let deliveryTime = 60;
        let samTime = 20;

        if(!point && !zone) return '----';

        if(point) {
            if(point.activate === 'disabledAll')
                return 'СТОП';

            if(point.activate === 'disabledDelivery') {
                deliveryTime = 'СТОП';
            } else {
                deliveryTime = point.minDeliveryTime;
            }

            if(point.activate === 'disabledOwn') {
                samTime = 'СТОП';
            } else {
                samTime = point.minSamTime;
            }

            return `${deliveryTime}/${samTime}`;
        }

        if(zone) {
            if(zone.point.id) {
                if(zone.point.activate === 'disabledAll')
                    return 'СТОП';
    
                if(zone.point.activate === 'disabledDelivery') {
                    deliveryTime = 'СТОП';
                } else {
                    deliveryTime = zone.point.minDeliveryTime;
                }
    
                if(zone.point.activate === 'disabledOwn') {
                    samTime = 'СТОП';
                } else {
                    samTime = zone.point.minSamTime;
                }
    
                return `${deliveryTime}/${samTime}`;
            } else {
                const chekPoint = this.props.filials.find((el) => el.id === zone.point);

                if(chekPoint.activate === 'disabledAll')
                    return 'СТОП';
    
                if(chekPoint.activate === 'disabledDelivery') {
                    deliveryTime = 'СТОП';
                } else {
                    deliveryTime = chekPoint.minDeliveryTime;
                }
    
                if(chekPoint.activate === 'disabledOwn') {
                    samTime = 'СТОП';
                } else {
                    samTime = chekPoint.minSamTime;
                }
    
                return `${deliveryTime}/${samTime}`;
            }
        }

        return 'Не опредлено';
    }

renderBody() { 
        return (
            <tbody className="kt-datatable__body order_table">
                {
                    this.props.orders.length === 0 && (
                        <tr className="kt-datatable__row order_table">
                            <td className="kt-datatable__cell order_table">Заказов не найдено</td>
                        </tr>
                    )
                }
                {
                    this.props.orders.map((item, orderIndex) => {                        
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className={`kt-datatable__row order_table ${item.status}`}>
                            <thead style={{ borderBottom: '1px solid #f2f3f8' }}>
                                <th className={`kt-indicator top ${item.status}`}></th>
                                <th 
                                onDoubleClick={((event) => {
                                    event.stopPropagation()
                                })}                                
                                >
                                    <div className='order_table_head' style={this.state.openClient ? {gap: '2px'} : {}}>
                                        <span style={this.state.openClient ? { fontSize: '0.9rem' } : {}}>{moment(this.renderTime(item)).format('HH:mm')}</span>
                                        <span style={this.state.openClient ? { fontSize: '0.9rem' } : {}}>{moment(this.renderTime(item)).format('DD.MM.YYYY')}</span>
                                        <span style={this.state.openClient ? { fontSize: '0.9rem' } : {}}>{'№ ' + item.id}</span>
                                        <button 
                                        className='order-table_arrow-show-details'
                                        onDoubleClick={((event) => {
                                            event.stopPropagation()
                                        })}   
                                        onClick={() => {
                                            this.toggleDetails(orderIndex)
                                        }}                   
                                        >                 
                                            <i className={`kt-menu__ver-arrow la la-angle-down${this.state.showDetails[orderIndex] ? ' Showed' : ''}`}></i>
                                        </button>
                                        <button className='button_order_table' onClick={this.handleClickCopy(moment(this.renderTime(item)).format('HH:mm') + ' ' + moment(this.renderTime(item)).format('DD.MM.YYYY') + '(№ ' + item.id + ')')}>
                                            <i className='kt-nav__link-icon flaticon2-copy order_table'/>
                                        </button>      
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <div style={{ position: 'absolute', display: 'flex', gap: '5px', alignItems: 'center'}}>
                                                {item.deliveryMethod === 'own' ? <span className='indicator_deliveryMethod'>Вынос</span> : <span className='indicator_deliveryMethod'>Доставка</span>}
                                                {item.isAggPrice && <span className='indicator_deliveryMethod'>ЯЕ</span>}
                                                {item.source !== 'crm' ? item.isSite ? <span className='indicator_deliveryMethod'>Сайт</span> : <span className='indicator_deliveryMethod'>Приложение</span> : ''}
                                            </div>
                                        </div>
                                    </div>                  
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                {!this.state.openClient && <th></th>}
                                <th></th>
                                <th></th>
                                <th>{this.renderStatus(item)}</th>
                                <th></th>
                            </thead>

                                <td className={`kt-indicator bottom ${item.status}`}></td>  
                                <td style={this.state.openClient ? { width: '300px', padding: '10px 5px'} : { width: '340px', padding: '10px 5px'}} className="kt-datatable__cell order_table">
                                    {!this.state.showDetails[orderIndex] && <button 
                                    className='order-table_button-show-details'
                                    onDoubleClick={((event) => {
                                        event.stopPropagation()
                                    })}   
                                    onClick={() => {
                                        this.toggleDetails(orderIndex)
                                    }}
                                    >
                                        Показать состав
                                    </button>}
                                
                                {
                                    this.state.showDetails[orderIndex] && 
                                    <table>
                                        {item.goods.map((good, index) => this.renderGoods(good, index, item.isAggPrice))}           
                                    </table>                                   
                                }
                                {
                                    this.state.showDetails[orderIndex] && item.unit &&
                                    <tr style={{ width: '340px'}}>
                                        <td>
                                            <div className="order-table-order-card__name">Приборы</div>
                                        </td>
                                        <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{item.unit}</td>
                                        <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>0</td>                     
                                    </tr>
                                }
                            </td>

                            <td className="kt-datatable__cell order_table" style={{ width: '60px', padding: '10px 5px' }}>
                                <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{item.totalPrice}</span>
                            </td>

                            <td className="kt-datatable__cell order_table" style={{ width: '60px', padding: '10px 5px' }}>
                                {item.salePrice && item.bonusCount && (item.salePrice > 0 || item.bonusCount > 0) && <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{parseFloat(item.salePrice) + parseFloat(item.bonusCount)}</span>}
                                {item.salePrice && item.salePrice > 0 && (!item.bonusCount || item.bonusCount === 0) && <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{item.salePrice}</span>}
                                {item.bonusCount && item.bonusCount > 0 && (!item.salePrice || item.salePrice === 0) && <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{item.bonusCount}</span>}
                                {(!item.bonusCount || item.bonusCount === 0) && (!item.salePrice || item.salePrice === 0) && <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}></span>}
                            </td>

                            <td className="kt-datatable__cell order_table" style={{ width: '160px', padding: '10px 5px' }}>
                                <span style={{ color: '#000', display: 'flex', justifyContent: 'center'  }}>
                                    {`${paymentTitle[item.payment]}${item.payment === 'cash' ? ', ' + сashTitle[item.changeCash] : ''}${item.payment === 'online' ? ', ' + this.getStatusPay(item) : ''}`}
                                </span>
                            </td>

                            {!this.state.openClient && <td className="kt-datatable__cell order_table" style={{ width: '70px', padding: '10px 5px' }}>
                                <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{this.renderPointTime(item.point, item.zone)}</span>
                            </td>}


                            <td className="kt-datatable__cell order_table" style={this.state.openClient ? { width: '180px' } : { width: '240px' }}>
                                <div style={this.state.openClient ? { width: '160px' } : { width: '220px' }}>
                                    <Select
                                        isLoading={this.props.loadingFilials || this.props.loadingUpdateorder === item.id}
                                        isDisabled={this.props.loadingFilials || this.props.loadingUpdateorder === item.id}
                                        value={this.renderSelectPoint(item)}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => {this.changePoint(e ? e.value : null, item)}}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }) 
                                          }}
                                        menuPlacement="auto"
                                        options={[
                                            { value: null, label: "Не определен" },
                                            ...this.props.filials.map(el => ({
                                                value: el.id,
                                                label: this.renderAdressSelect(el)
                                            }))
                                        ]}                                     
                                        placeholder="Филиал"
                                        noOptionsMessage={() => 'Нет городов'}
                                    />                                    
                                </div>
                            </td>
                             
                            <td className="kt-datatable__cell order_table" style={this.state.openClient ? { minWidth: '200px' } : { minWidth: '350px' }}>
                                <span style={{ width: '100%', display: 'flex', gap: '5px', alignItems: 'center', color: '#000'  }}>                                     
                                    {this.renderAdress(item)}
                                    {item.comment ? ', Комментарий: ' + item.comment : ''} {item.deliveryTime ? `, Заказ ко времени: ${moment(item.deliveryTime).format('DD.MM.YYYY в HH:mm')}` : ''}
                                    <button className='button_order_table' onClick={this.handleClickCopy(String((item.city ? item.city.name : '') + (item.area ? ', ' + item.area : '') + (item.street ? ', ' + item.street : '') + (item.house ? ', ' + item.house : '')))}>
                                        <i className='flaticon2-copy order_table'/>
                                    </button>
                                </span>
                            </td>  

                            <td onDoubleClick={((event) => {event.stopPropagation()})} className="kt-datatable__cell order_table" style={{ width: '140px' }}>
                                <span style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#000', justifyContent: 'center' }}>
                                    {item.phone} 
                                    <button className='button_order_table' onClick={this.handleClickCopy(String(item.phone))}>
                                        <i className='kt-nav__link-icon flaticon2-copy order_table'/>
                                    </button>
                                </span>
                            </td>

                            {(this.props.role === roleId.admin || this.props.role === roleId.manager || this.props.role === roleId.regionalDirector) &&
                            <td className="kt-datatable__cell order_table" style={{ width: '1.5rem'}}>
                                <button
                                className='button_order_table'
                                onClick={(() => {
                                    this.props.onDelete(item.id)
                                })}
                                >                                    
                                    <i className='kt-nav__link-icon flaticon2-trash order_table'></i>
                                </button>                           
                            </td>
                            }
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        if(this.props.loading)
            return (
                <div style={styles.loading}><Loader /></div>
            );

        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px', backgroundColor: '#00000000' }}>
                {this.renderBody()}
            </table>
        );
    }
}

const mapStateToProps = state => ({
    orders: state.orders.list,
    filters: state.orders.filters,
    loading: state.loaders.orders || state.loaders.filials || state.loaders.settings || fromStore.loaderCategoriesSelector(state),
    filials: state.handbook.filials,
    role: state.user.info.role.id,
    settings: state.settings.init.categories,
    loadingUpdateorder: state.loaders.updateorder,  
    categories: fromStore.categoryListSelector(state)
});

const mapDispatchToProps = {
    updateOrderFromTable: (id, data, isArchive) => updateOrderFromTable(id, data, isArchive),
    fetchFilials: (city) => fetchFilials(city, true),
    fetchSettings: () => fetchSettings(),
    fetchCategories: () => fetchCategories()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdersTable));