import React, { Component } from 'react';
import ActionMenu from '../utils/ActionMenu';
import { withRouter  } from 'react-router-dom';
import { roleId } from '../../constants/app';

class TableCities extends Component {
    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Город</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '200px' }}>Филиалы</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Время работы</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Время доставки</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Стоимость доставки</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '80px' }}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/handbook/cities/edit/${+item.id}`)
    }

    renderFilial(items) {
        return items.map(item => <div key={item.id}>{item.street}, {item.house}{item.entrance ? `, подъезд ${item.entrance}` : ''}{item.room ? `, кв./оф. ${item.room}` : ''}</div>)
    }
    
    renderBody() {
        
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/handbook/cities/${+item.id}`) },
                            { access: roleId.admin, name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/cities/edit/${+item.id}`) },
                            { access: roleId.admin, name: 'Добавить филиал', icon: 'flaticon2-mail-1', action: () => this.props.history.push({ pathname: '/handbook/filials/add', state: { city: item.id } })},
                            { access: roleId.admin, name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.deleteItem(item) }
                        ].filter(item => !item.access || item.access === this.props.role);
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>
                                    {item.name}
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '200px' }}>
                                    { item.points.length ? this.renderFilial(item.points) : 'Нет филиалов' }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>
                                    { item.deliveryWork }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>
                                    { item.deliveryTime }     
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>
                                    { item.deliveryCost } руб.     
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        if (this.props.items.length === 0) {
            return (
                <div>Городов не найдено</div>
            );
        }
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(TableCities);