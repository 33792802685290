import React, { Component, Fragment } from "react";
import { deleteUser, fetchUsers, setUsersFilters, setUsersPaging } from "../../action/user";
import { fetchCities, fetchFilials } from "../../action/handbook";
import { connect } from "react-redux";
import Select from 'react-select';
import swal from 'sweetalert';
import ReactPaginate from "react-paginate";
import Loader from "../utils/Loader";
import Portlet from "../utils/Portlet";
import ErrorContainer from "../utils/ErrorContainer";
import Input from "../utils/Input";
import ActionMenu from "../utils/ActionMenu";
import { roleId } from "../../constants/app";

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class UsersList extends Component {
    state = {
        currentPage: 0,
    }

    componentDidMount() {
        this.props.fetchUsers();
        this.props.fetchCities();
        this.props.fetchFilials(null);
    }

    componentWillUnmount() {
        const { paging } = this.props;
        this.props.setUsersPaging({ ...paging, start: 0 });
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setUsersPaging(updatedPaging);
        this.props.fetchUsers();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setUsersPaging(updatedPaging);
            this.props.fetchUsers();
        });
    };

    deleteUser = (id) => {
        swal({
            title: "Удаление пользователя",
            text: "Вы действительно хотите удалить пользователя?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                this.props.deleteUser(id)
            }
        });
    }

    renderAdress(item) {
        return `${item.city.name}, ${item.street}, ${item.house}`;
    }

    renderAdressPoint(item) {
        if(!item.points)
            return '---'

        if(item.points.length === 1)
        {
            const City = this.props.cities.find((city) => city.id === item.points[0].city)
            return `${City ? `${City.name}, ` : ''}${item.points[0].street}, ${item.points[0].house}`
        }

        if(item.allPoints) {
            return 'Все филиалы'
        }

        return 'Несколько филиалов'
    }

    renderRole(roleId) {
        switch(roleId) {
            case 3: {
                return 'Управляющий'
            }
            case 5: {
                return 'Координатор'
            }
            case 8: {
                return 'Оператор'
            }
            case 17: {
                return 'Кассир'
            }
            case 18: {
                return 'Товаровед'
            }
            case 19: {
                return 'Бухгалтер'
            }
            case 20: {
                return 'Маркетолог'
            }            
            default: {
                return '---'
            }
        }
    }

    setUsersFilters = (field) => (data) => {
        const { filters } = this.props;
        const updated = {
            ...filters,
            [field]: data
        }
        if (field === 'city') {
            updated.point = null;
            this.props.fetchFilials(data.value)
        }
        this.props.setUsersFilters(updated);
        this.props.fetchUsers();
    }

    onChangeInput = (field) => (e) => {
        const value = e.target.value;

        const { filters } = this.props;
        const updated = {
            ...filters,
            [field]: value
        }
        this.props.setUsersFilters(updated);
    }

    resetFilter = () => {
        this.props.setUsersFilters({ city: null, point: null });
        this.setState({ currentPage: 0 });
        this.props.fetchUsers();
    }

    onSearch = () => {
        const { paging } = this.props;
        this.props.setUsersPaging({ ...paging, start: 0 });
        this.setState({ currentPage: 0 });
        this.props.fetchUsers();
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.onSearch(); 
        }
    }

    handleDoubleClick = (item) => () => {
        this.props.history.push(`/users/edit/${+item.id}`)
    }

    openAddUser = () => {
        this.props.history.push('/users/add')
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Пользователи</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {this.props.count}
                        </span>
                    </div>
                </div>        
               
            </div>
            </div>
        );
    }
    
    renderInputs(){
        return(
            <div className="kt-portlet__body">
                        <div className="kt-form kt-form--label-right kt-margin-b-10">
                            <div className="filterBlocks" onKeyDown={this.onKeyDown}>
                                <div>
                                    <Input placeholder="Логин" onChange={this.onChangeInput('username')} disableGroup={true} value={this.props.filters.name}/>
                                </div>                
                                <div style={{ width: 300 }}>
                                    <Select
                                        isLoading={this.props.loadingCity}
                                        isDisabled={this.props.loadingCity}
                                        value={this.props.filters.city}
                                        closeMenuOnSelect={true}
                                        onChange={this.setUsersFilters('city')}
                                        options={this.props.cities.map(item => ({
                                            value: item.id,
                                            label: item.name
                                        }))}
                                        placeholder="Город"
                                        noOptionsMessage={() => 'Нет городов'}
                                    />
                                </div>
                                <div style={{ width: 300 }}>
                                    <Select
                                        isLoading={this.props.loadingFilials}
                                        isDisabled={this.props.loadingFilials}
                                        value={this.props.filters.point}
                                        closeMenuOnSelect={true}
                                        onChange={this.setUsersFilters('point')}
                                        options={this.props.filials.map(item => ({
                                            value: item.id,
                                            label: this.renderAdress(item)
                                        }))}
                                        placeholder="Филиал"
                                        noOptionsMessage={() => 'Нет филиалов'}
                                    />
                                </div>
                                <div>
                                    <button style={{ marginRight: 5 }} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                                    <button style={{ marginRight: 5 }} onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                                    <button onClick={this.openAddUser}className="btn btn-success">Добавить пользователя</button>
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }

    renderBody() {
        return(
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                <thead className="kt-datatable__head">
                    <tr className="kt-datatable__row">                        
                        <th className="kt-datatable__cell">
                            <span style={{ width: '450px' }}>Имя</span>
                        </th>
                        <th className="kt-datatable__cell">
                            <span style={{ width: '350px' }}>Филиал</span>
                        </th>
                        <th className="kt-datatable__cell">
                            <span style={{ width: '250px' }}>Роль</span>
                        </th>
                        <th className="kt-datatable__cell">
                            <span style={{ width: '110px' }}>Статус</span>
                        </th>
                        <th className="kt-datatable__cell">
                            <span style={{ width: '80px' }}>Действия</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="kt-datatable__body">
                {
                    this.props.users.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Пользователей не найдено</td>
                        </tr>
                    )
                }
                {
                    this.props.users.map(item => {
                        const actions = [
                            { name: 'Редактирование', access: roleId.admin, icon: 'flaticon2-contract', action: () => this.props.history.push(`/users/edit/${+item.id}`) },
                            { name: 'Удалить', access: roleId.admin, icon: 'flaticon2-trash', action: () => this.deleteUser(item.id) }
                        ].filter(action => {
                            if (action.access && action.access !== this.props.role) {
                                return false;
                            }                            
                            return true;                            
                        });
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '450px' }}>{item.name ? item.name : '---'}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '350px' }}>
                                    { this.renderAdressPoint(item) }
                                    </span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '250px' }}>
                                        {item.role.description}
                                    </span>
                                </td>
                                <td className="kt-datatable__cell--sorted kt-datatable__cell">
                                    <span style={{ width: '110px' }}>
                                        {
                                            item.confirmed ? (
                                                <span className="kt-badge kt-badge--brand kt-badge--inline">активен</span>
                                            ) : (
                                                <span className="kt-badge kt-badge--warning kt-badge--inline">не подтвержден</span>
                                            )
                                        }
                                    </span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <ActionMenu actions={actions}/>
                                </td>
                            </tr>
                        )
                    }) 
                }
                </tbody>
            </table>            
        );
    }

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={8}
                    pageRangeDisplayed={5}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>    
        );
    }

    render() {
        if (this.props.loading) 
            return <div style={styles.loading}><Loader /></div>            
        
        return(
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    { this.renderInputs() }
                    <Portlet fit={true}>
                        <ErrorContainer field="users" style={{ margin: 15 }} hasCloseButton={true}/>
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                            { this.renderBody() }
                            { this.props.users.length > 0 && this.renderPagination() }
                        </div>                        
                    </Portlet>
                </div>
            </Fragment>
        );
    }
}



const mapStateToProps = state => ({
    users: state.users.list,
    filters: state.users.filters,
    loading: state.loaders.users,
    count: state.users.count,
    paging: state.users.paging,
    cities: state.handbook.cities,
    loadingCity: state.loaders.cities,
    filials: state.handbook.filials,
    loadingFilials: state.loaders.filials,
    role: state.user.info.role.id
});

const mapDispatchToProps = {
    fetchUsers: () => fetchUsers('users'),
    setUsersPaging: (paging) => setUsersPaging(paging),
    deleteUser: (id) => deleteUser(id, 'users'),
    fetchCities: () => fetchCities(true),
    fetchFilials: (city) => fetchFilials(city, true),
    setUsersFilters: (filters) => setUsersFilters(filters)
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);