export function addPositionNewOrder(good, setting) {
    return (dispatch) => {
        dispatch({ type: 'ADD_POSITION_NEW_ORDER', setting: setting, good: good }); 
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
    }
}

export function deletePositionNewOrder(index) {
    return (dispatch) => {
        dispatch({ type: 'DELETE_POSITION_NEW_ORDER', index: index }); 
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
    }
}

export function changeCountPosition(index, count, type, value) {
    if(type === 'plus' && count < 100)
        return (dispatch) => {
            dispatch({ type: 'PLUS_COUNT_POSITION_NEW_ORDER', index: index }); 
            dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
        }

    if(type === 'minus')  
    {  
        if(count === 1)
            return (dispatch) => {
                dispatch({ type: 'DELETE_POSITION_NEW_ORDER', index: index }); 
                dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
            }

        return (dispatch) => {
            dispatch({ type: 'MINUS_COUNT_POSITION_NEW_ORDER', index: index }); 
            dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
        }
    }        
    
    if(type === 'handle')
    {
        if(parseInt(value) >= 99)
            return (dispatch) => {
                dispatch({ type: 'CHANGE_COUNT_POSITION_NEW_ORDER', index: index, value: 99}); 
                dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
            }

        return (dispatch) => {
            dispatch({ type: 'CHANGE_COUNT_POSITION_NEW_ORDER', index: index, value: parseInt(value)}); 
            dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
        }
    }
}

export function changeModificationOrderPositionList(index, mod, good){
    return (dispatch) => {
        dispatch({ type: 'CHANGE_MODIFICATION_NEW_ORDER', index: index, mod: mod, checkGood: good}); 
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
    }
}

export function addAdditivesWOK(index, good, settings){
    return (dispatch) => {
        dispatch({ type: 'ADD_ADDITIVES_NEW_ORDER', index: index, good: good, settings: settings}); 
        dispatch({type: 'CALCULATE_WOK_PRICE', index: index})
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
    }
}

export function deleteAdditivesNewOrder(index, idAdditives, subtype) {
    return (dispatch) => {
        dispatch({ type: 'DELETE_ADDITIVES_NEW_ORDER', index: index, idAdditives: idAdditives, subtype: subtype }); 
        dispatch({type: 'CALCULATE_WOK_PRICE', index: index})
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
    }
}

export function changeCountAdditives(index, count, type, idAdditives, subtype, value) {
    if(type === 'plus' && count < 100)
        return (dispatch) => {
            dispatch({ type: 'PLUS_COUNT_ADDITIVES_NEW_ORDER', index: index, idAdditives: idAdditives, subtype: subtype }); 
            dispatch({type: 'CALCULATE_WOK_PRICE', index: index})
            dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
        }

    if(type === 'minus')  
    {  
        if(count === 1)
            return (dispatch) => {
                if(subtype === 'main')
                    dispatch({ type: 'DELETE_POSITION_NEW_ORDER', index: index }); 
                if(subtype !== 'main')
                    dispatch({ type: 'DELETE_ADDITIVES_NEW_ORDER', index: index, idAdditives: idAdditives, subtype: subtype }); 
                dispatch({type: 'CALCULATE_WOK_PRICE', index: index})
                dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
            }

        return (dispatch) => {
            dispatch({ type: 'MINUS_COUNT_ADDITIVES_NEW_ORDER', index: index, idAdditives: idAdditives, subtype: subtype }); 
            dispatch({type: 'CALCULATE_WOK_PRICE', index: index})
            dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
        }
    }        
    
    if(type === 'handle')
    {
        if(parseInt(value) >= 99)
            return (dispatch) => {
                dispatch({ type: 'CHANGE_COUNT_ADDITIVES_NEW_ORDER', index: index, value: 99, idAdditives: idAdditives, subtype: subtype }); 
                dispatch({type: 'CALCULATE_WOK_PRICE', index: index})
                dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
            }

        return (dispatch) => {
            dispatch({ type: 'CHANGE_COUNT_ADDITIVES_NEW_ORDER', index: index, value: parseInt(value), idAdditives: idAdditives, subtype: subtype }); 
            dispatch({type: 'CALCULATE_WOK_PRICE', index: index})
            dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
        }
    }
}

export function changeUnit(value) {
    if(value === 0)
        return (dispatch) => {
            dispatch({ type: 'CHANGE_INFORMATION_NEW_ORDER', subtype: 'unit', value: null }); 
        }
    
    return (dispatch) => {
        dispatch({ type: 'CHANGE_INFORMATION_NEW_ORDER', subtype: 'unit', value: value }); 
    }
}

export function changeSale(type, value) {
    if(type === 'percent'){
        return (dispatch) => {
            dispatch({ type: 'SET_SALE_PERCENT_NEW_ORDER', value: value }); 
            dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
        }
    }

    return (dispatch) => {
        dispatch({ type: 'SET_SALE_SUM_NEW_ORDER', value: value }); 
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
    }
}

export function changePointNewOrder(value) {    
    return (dispatch) => {
        dispatch({ type: 'CHANGE_POINT_NEW_ORDER', value: value})
    }
}

export function calculationTotalPriceNewOrder() {
    return (dispatch) => {
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
    }
}

export function setZonesNewOrder(value) {
    if(value)
    {
        const data = value.filter(((item) => item.deliveryPrice > 0))
        let filteredData = data.map(item => ({
            deliveryPrice: item.deliveryPrice,
            deliveryCode: item.deliveryCode,
        }));

        if(filteredData.length === 0)
            filteredData = null

        return (dispatch) => {
            dispatch({ type: 'SET_ZONES_NEW_ORDER', value: filteredData})
        }
    }
    
    return (dispatch) => {
        dispatch({ type: 'SET_ZONES_NEW_ORDER', value: null})
    }    
}

export function addDeliveryPriced(price, article){
    return (dispatch) => {
        dispatch({ type: 'ADD_DELIVERY_PRICED_NEW_ORDER', price: price, article: article})
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
    }    
}

export function changeDeliveryMethodNewOrder(value) {    
    return (dispatch) => {
        dispatch({ type: 'CHANGE_DELIVERY_METHOD_NEW_ORDER', value: value})
            if(value === 'own')
            {
                dispatch({ type: 'ADD_DELIVERY_PRICED_NEW_ORDER', price: 0, article: null})
                dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER'}); 
            }
    }
}

export function changeIsAggPriceNewOrder(value, goodsChek) {    
    return (dispatch) => {
        dispatch({ type: 'CHANGE_AGG_PRICE_NEW_ORDER', value: value})
        dispatch({ type: 'CALCULATION_TOTALPRICE_NEW_ORDER', goodsChek: goodsChek}); 
    }
}

export function changeInformationNewOrder(subtype, value) {    
    return (dispatch) => {
        dispatch({ type: 'CHANGE_INFORMATION_NEW_ORDER', subtype: subtype, value: value})
    }
}

export function changePaymentNewOrder(value) {
    return (dispatch) => {
        dispatch({ type: 'CHANGE_PAYMENT_NEW_ORDER', value: value})
    }
}

export function changeChangeCashNewOrder(value) {
    return (dispatch) => {
        dispatch({ type: 'CHANGE_CHANGECASH_NEW_ORDER', value: value})
    }
}

export function clearNewOrder() {
    return(dispatch) => {
        dispatch({ type: 'CLEAR_NEW_ORDER'}); 
    }
}

export function saveNewOrder(){
    return(dispatch) => {
        dispatch({ type: 'SAVE_NEW_ORDER'}); 
    }
}

export function openEditOrder(order, point){
    return(dispatch) => {
        dispatch({ type: 'OPEN_EDIT_ORDER', order: order, point: point})
    }
}