import { urls, baseUrl } from '../constants/app';
import request from './utils';
import axios from 'axios';
import moment from 'moment';
axios.defaults.baseURL = baseUrl;


export function fetchPopGoodsStat() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_STATISTICS_LOADER', name: 'popGoods', loading: true });
        dispatch({ type: 'SET_STATISTICS_ERROR', name: 'popGoods', error: null });

        const filters = getState().statistics.popGoods.filters;
        const params = {};

        if (filters.city) {
            params['city.id'] = filters.city.value;
        }

        if (filters.createdStartDate) {
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }
    
        if (filters.createdEndDate) {
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        request({ method: 'get', url: urls.orders+'/stats', params })
            .then(result => {
                dispatch({ type: 'SET_STATISTICS', name: 'popGoods', result });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'popGoods', loading: false });
            })
            .catch(error => {
                dispatch({ type: 'SET_STATISTICS_ERROR', name: 'popGoods', error });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'popGoods', loading: false });
            });
    }
}

export function fetchOrdersStat() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_STATISTICS_LOADER', name: 'orders', loading: true });
        dispatch({ type: 'SET_STATISTICS_ERROR', name: 'orders', error: null });

        const filters = getState().statistics.orders.filters;
        const params = {};

        if (filters.city) {
            params['city.id'] = filters.city.value;
        }

        if (filters.createdStartDate) {
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }
    
        if (filters.createdEndDate) {
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        request({ method: 'get', url: urls.orders+'/stats-order', params })
            .then(result => {
                dispatch({ type: 'SET_STATISTICS', name: 'orders', result });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'orders', loading: false });
            })
            .catch(error => {
                dispatch({ type: 'SET_STATISTICS_ERROR', name: 'orders', error });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'orders', loading: false });
            });
    }
}


export function fetchPromocodeStat() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_STATISTICS_LOADER', name: 'promocode', loading: true });
        dispatch({ type: 'SET_STATISTICS_ERROR', name: 'promocode', error: null });

        const filters = getState().statistics.promocode.filters;
        const params = {};

        if (filters.city) {
            params['city.id'] = filters.city.value;
        }

        if (filters.createdStartDate) {
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }
    
        if (filters.createdEndDate) {
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        request({ method: 'get', url: urls.orders+'/stats-promocode', params })
            .then(result => {
                dispatch({ type: 'SET_STATISTICS', name: 'promocode', result });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'promocode', loading: false });
            })
            .catch(error => {
                dispatch({ type: 'SET_STATISTICS_ERROR', name: 'promocode', error });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'promocode', loading: false });
            });
    }
}

export function fetchReffersStat() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_STATISTICS_LOADER', name: 'refferers', loading: true });
        dispatch({ type: 'SET_STATISTICS_ERROR', name: 'refferers', error: null });

        const filters = getState().statistics.refferers.filters;

        const params = {};

        if (filters.city) {
            params['city.id'] = filters.city.value;
        }

        if (filters.createdStartDate) {
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }
    
        if (filters.createdEndDate) {
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        request({ method: 'get', url: urls.statistics+'/referers', params })
            .then(result => {
                dispatch({ type: 'SET_STATISTICS', name: 'refferers', result });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'refferers', loading: false });
            })
            .catch(error => {
                dispatch({ type: 'SET_STATISTICS_ERROR', name: 'refferers', error });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'refferers', loading: false });
            });
    }
}

export function fetchClientsStat() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_STATISTICS_LOADER', name: 'clients', loading: true });
        dispatch({ type: 'SET_STATISTICS_ERROR', name: 'clients', error: null });

        const filters = getState().statistics.clients.filters;
        const params = {};
        if (filters.city) {
            params['city'] = filters.city.value;
        }

        if (filters.createdStartDate) {
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }
    
        if (filters.createdEndDate) {
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        return request({ method: 'get', url: urls.orders+'/stats-clients', params })
            .then(result => {
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'clients', loading: false });
                return result;
            })
            .catch(error => {
                dispatch({ type: 'SET_STATISTICS_ERROR', name: 'clients', error });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'clients', loading: false });
            });
    }
}

export function fetchPointsLogs(partUrl, field) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_STATISTICS_LOADER', name: 'pointslogs', loading: true });
        dispatch({ type: 'SET_STATISTICS_ERROR', name: 'pointslogs', error: null });
        
        try {
            const filters = getState().statistics.pointslogs.filters;
            const params = {};

            if (filters.city) {
                params['city'] = filters.city.value;
            }

            if (filters.createdStartDate) {
                params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
            }
        
            if (filters.createdEndDate) {
                params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
            }
            
            if (field) {
                params['field'] = field;
            }

            const url = partUrl ? urls.pointsLogs+'/stats-pointslogs-'+partUrl : urls.pointsLogs+'/stats-pointslogs';
            const items = await request({ method: 'get', url: url, params });
            dispatch({ type: 'SET_STATISTICS_LOADER', name: 'pointslogs', loading: false });

            return items;
        } catch (error) {
                dispatch({ type: 'SET_STATISTICS_ERROR', name: 'pointslogs', error });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'pointslogs', loading: false });
        }
    }
}

export function fetchNewClientsStat() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_STATISTICS_LOADER', name: 'clients', loading: true });
        dispatch({ type: 'SET_STATISTICS_ERROR', name: 'clients', error: null });

        const filters = getState().statistics.clients.filters;
        const params = {};
        if (filters.city) {
            params['city'] = filters.city.value;
        }

        if (filters.createdStartDate) {
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }
    
        if (filters.createdEndDate) {
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        return request({ method: 'get', url: urls.orders+'/stats-NewClients', params })
            .then(result => {
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'clients', loading: false });
                return result;
            })
            .catch(error => {
                dispatch({ type: 'SET_STATISTICS_ERROR', name: 'clients', error });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'clients', loading: false });
            });
    }
}

export function fetchRobovoiceCallsStatusStat() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_STATISTICS_LOADER', name: 'orders', loading: true });
        dispatch({ type: 'SET_STATISTICS_ERROR', name: 'orders', error: null });

        const filters = getState().statistics.orders.filters;
        const params = {};
        if (filters.city) {
            params['city'] = filters.city.value;
        }

        if (filters.createdStartDate) {
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }
    
        if (filters.createdEndDate) {
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        return request({ method: 'get', url: urls.orders + '/stats-RobovoiceCallsStatus', params })
            .then(result => {
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'orders', loading: false });
                return result;
            })
            .catch(error => {
                dispatch({ type: 'SET_STATISTICS_ERROR', name: 'orders', error });
                dispatch({ type: 'SET_STATISTICS_LOADER', name: 'orders', loading: false });
            });
    }
}

export function setStatFilters(name, filters) {
    return dispatch => {
        dispatch({ type: 'SET_STATISTICS_FILTERS', name, filters });
    }
}

export function resetStatFilters(name) {
    return dispatch => {
        dispatch({ type: 'RESET_STATISTICS_FILTERS', name });
    }
}